











































































































































































































































































































































































@import "https://js.arcgis.com/4.19/@arcgis/core/assets/esri/themes/light/main.css";

#appka {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;

  .map-holder {
    height: 100%;
    .map-holder-inner {
      height: 100%;
    }
  }
}
.searchbox-embed-param {

    width: 400px !important;
  
}

