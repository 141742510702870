


















































































































































































































* {
  font-family: "LyseSans", sans-serif;
}
.popup-content {
  text-align: center;
  .status-normal {
    color: #38a800;
  }
  .status-registered {
    color: #ffa900;
  }
  .status-created {
    color: #ff0000;
  }
  .status-in-progress {
    color: #006fff;
  }
  .status-planned {
    color: #C500FF;
  }
}

.popup-header {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  margin-bottom: 0.75rem;
  .popup-title {
    flex: 1;
  }
  .popup-buttons {
    display: flex;
  }
}

i {
  padding-bottom: 5px;
}
p.description {
  font-size: 0.7rem;
  padding-top: 10px;
}
.title.status-title.is-5 {
  margin-bottom: 1rem;
}

table {
  margin: 0 auto 1rem auto;
  font-size: 14px;
  
  td {
    text-align: left;
  }
  td:first-child {
    font-weight: 700;
    text-align: right;
    padding-right: 10px;
  }
}
