




























































@import 'bulma/sass/utilities/initial-variables';

div .gatelys-map {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

