// BUEFY CUSTOMIZATION

// Import Bulma's core
// @import "~bulma/sass/utilities/_all.sass";
$sidebar-width: 500px;

$link: rgb(14,77,57);
$primary: #246E2E;

// Import Bulma and Buefy styles
@import "~bulma/bulma.sass";
@import "~buefy/src/scss/buefy";