









































































































.wrapper {
    max-height: 600px;
    min-height: 380px;
    overflow-y: auto;
    overflow-x: hidden;

    svg {
        position: relative;
        top: -3px;
    }
}
.title.is-6 {
    margin-bottom: 4px;
}
