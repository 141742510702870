@font-face {
  font-family: 'LyseSans';
  src: url('../assets/fonts/LyseSans-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LyseSans';
  src: url('../assets/fonts/LyseSans-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'LyseSans';
  src: url('../assets/fonts/LyseSans-BoldItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'LyseSans';
  src: url('../assets/fonts/LyseSans-Heavy.woff2') format('woff2');
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: 'LyseSans';
  src: url('../assets/fonts/LyseSans-Light.woff2') format('woff2');
  font-weight: lighter;
  font-style: normal;
}

* {
  font-family: "LyseSans", sans-serif;
}

html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  letter-spacing: 0em;
  font-family: "LyseSans", sans-serif;
  font-feature-settings: "liga" 1, "calt" 0;
}

button{
  font-family: "LyseSans", sans-serif;
}

