





















































































































































































































































































































































































































































































































































































































































































@import "bulma/sass/utilities/initial-variables";

@media only screen and (max-width: $tablet) {
}

@media only screen and (min-width: $tablet) {
  .fault-form {
    position: absolute;
    right: 0px;
    top: 3.25rem;
    width: 25%;
    background: #ffffffdb;
    height: auto;

    .section {
      padding: 1em;
    }
  }
}
input[type="file"] {
  display: none;
}
.file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: whitesmoke;
  &:hover {
    background-color: rgb(237, 237, 237);
  }
}
.selected-file {
  padding: 10px;
}
